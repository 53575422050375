import {FullAddressContactDetails, CommonVatId, CommonVatType} from '@wix/ambassador-ecom-v1-checkout/types';
import {FullAddressContactDetailsFragment} from '../../gql/graphql';

export class ContactModel {
  public firstName?: string;
  public lastName?: string;
  public company?: string;
  public phone?: string;
  public vatId?: CommonVatId;

  constructor(contactDetails?: FullAddressContactDetails | FullAddressContactDetailsFragment) {
    this.firstName = contactDetails?.firstName ?? /* istanbul ignore next */ undefined;
    this.lastName = contactDetails?.lastName ?? /* istanbul ignore next */ undefined;
    this.company = contactDetails?.company ?? /* istanbul ignore next */ undefined;
    this.phone = contactDetails?.phone ?? /* istanbul ignore next */ undefined;
    this.vatId = contactDetails?.vatId
      ? {
          id: contactDetails.vatId.id ?? /* istanbul ignore next */ undefined,
          type: contactDetails.vatId.type
            ? (contactDetails.vatId.type as CommonVatType)
            : /* istanbul ignore next */ undefined,
        }
      : /* istanbul ignore next */ undefined;
  }
}
