import React, {ComponentType, createContext, useContext, useEffect} from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FullAddressContactDetails, ApiAddress} from '@wix/ambassador-ecom-v1-checkout/types';
import {
  getContactDetailsFromContactFormValues,
  getContactFormInitialState,
} from '../../../../Form/ContactForm/contactForm.utils';
import {getEmailFormInitialState, getEmailFromEmailFormValues} from '../../../../Form/EmailForm/emailForm.utils';
import {
  getAdditionalInfoFormInitialState,
  getCustomFieldFromAdditionalInfoFormValues,
} from '../../../../Form/AdditionalInfoForm/AdditionalInfoForm';
import {
  getAddressFormInitialState,
  getAddressFromAddressFormValues,
} from '../../../../Form/AddressForm/addressForm.utils';
import {FormInstanceData, useFormInstance} from '../../../../Form/useFormInstance';
import {AddressWithContactModel} from '../../../../../../domain/models/AddressWithContact.model';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';

export type CustomerDetailsDataContextType = {
  emailForm: FormInstanceData;
  contactForm: FormInstanceData;
  additionalInfoForm: FormInstanceData;
  addressForm: FormInstanceData;
  areFormsValid: () => Promise<boolean>;
  getCustomerDetailsForSubmit: () => {
    contactDetails?: FullAddressContactDetails;
    email?: string;
    customFieldValue?: string;
    shippingAddress?: ApiAddress;
  };
};

export const CustomerDetailsDataContext = createContext({} as CustomerDetailsDataContextType);

export function withCustomerDetailsData<T extends object>(Component: ComponentType<T>) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout, isShippingFlow},
      checkoutSettingsStore: {checkoutSettings},
      memberStore: {isMember},
    } = useControllerProps();

    const {editMode, selectedAddressesService} = useMemberDetailsData();

    const emailForm = useFormInstance(getEmailFormInitialState(checkout.buyerInfo));
    const contactForm = useFormInstance(
      getContactFormInitialState({
        checkoutSettings,
        contact: checkout.shippingDestination?.contact,
        country: checkout.shippingDestination?.address?.country,
      })
    );
    const additionalInfoForm = useFormInstance(getAdditionalInfoFormInitialState(checkout.customField));
    const addressForm = useFormInstance(
      getAddressFormInitialState(checkoutSettings, checkout.shippingDestination?.address)
    );

    const forms = [
      contactForm,
      additionalInfoForm,
      ...(!isMember ? [emailForm] : []),
      ...(isShippingFlow ? [addressForm] : []),
    ];

    const areFormsValid = async () => {
      const areFormsValidArr = await Promise.all(forms.map(({isValid}) => isValid()));

      return !areFormsValidArr.includes(false);
    };

    const areFormsRendered = () => forms.some((form) => form.isRendered());

    const getCustomerDetailsForSubmit = () => {
      const addressAndContact = areFormsRendered()
        ? {
            contactDetails: getContactDetailsFromContactFormValues(contactForm.data.formValues, checkoutSettings),
            shippingAddress: isShippingFlow
              ? getAddressFromAddressFormValues(checkoutSettings, addressForm.data.formValues)
              : undefined,
          }
        : {};
      return {
        ...addressAndContact,
        email: !isMember ? getEmailFromEmailFormValues(emailForm.data.formValues) : checkout.buyerInfo.email,
        customFieldValue: checkoutSettings.customField.show
          ? getCustomFieldFromAdditionalInfoFormValues(additionalInfoForm.data.formValues)
          : undefined,
      };
    };
    const initForm = (shippingDestination: AddressWithContactModel) => {
      contactForm.data.setFormValues(
        getContactFormInitialState({
          checkoutSettings,
          contact: shippingDestination?.contact,
          country: shippingDestination?.address?.country,
        })
      );
      additionalInfoForm.data.setFormValues(getAdditionalInfoFormInitialState(checkout.customField));
      addressForm.data.setFormValues(getAddressFormInitialState(checkoutSettings, shippingDestination?.address));
    };

    useEffect(
      () => {
        if (editMode) {
          initForm(selectedAddressesService!);
        }
      },
      /* eslint-disable react-hooks/exhaustive-deps*/ [editMode]
    );

    return (
      <CustomerDetailsDataContext.Provider
        value={{
          contactForm,
          emailForm,
          additionalInfoForm,
          addressForm,
          areFormsValid,
          getCustomerDetailsForSubmit,
        }}>
        <Component {...props} />
      </CustomerDetailsDataContext.Provider>
    );
  };
}

export function useCustomerDetailsData() {
  const {contactForm, addressForm, additionalInfoForm, emailForm, areFormsValid, getCustomerDetailsForSubmit} =
    useContext(CustomerDetailsDataContext);

  return {
    contactFormData: contactForm.data,
    addressFormData: addressForm.data,
    additionalInfoFormData: additionalInfoForm.data,
    emailFormData: emailForm.data,
    areFormsValid,
    getCustomerDetailsForSubmit,
  };
}
