import {ControllerProps} from '../../../types/app.types';
import {AddressWithContactModel} from '../../../domain/models/AddressWithContact.model';
import React, {ComponentType, createContext, useContext, useEffect, useState} from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {CheckoutModel} from '../../../domain/models/Checkout.model';
import {applyOverrides as applyRuleOverrides} from '@wix/form-conditions/dist/types/lib/apply-overrides';
import {CheckoutSettingsModel} from '../../../domain/models/checkoutSettings/CheckoutSettings.model';

export type MemberDetailsDataContextType = {
  selectedAddressesServiceId?: string;
  selectedAddressesService?: AddressWithContactModel;
  setAddressServiceById: (id?: string) => void;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  resetMemberDetailsState: () => void;
  areMemberDetailsValid: boolean;
  isAddNewChecked: boolean;
  setIsAddNewChecked: (checked: boolean) => void;
};

export type MemberDetailsValidator<S> = (options: {
  formsById: {[key: string]: ReturnType<typeof applyRuleOverrides>};
  addressAndContact: AddressWithContactModel;
  checkoutSettings: CheckoutSettingsModel;
  context: S;
}) => Promise<boolean>;

export const MemberDetailsDataContext = createContext({} as MemberDetailsDataContextType);

/* eslint-disable sonarjs/cognitive-complexity */
export function withMemberDetailsData<T extends object, S>(
  Component: ComponentType<T>,
  getAddressAndContactFromCheckout: (checkout: CheckoutModel) => AddressWithContactModel | undefined,
  validator: MemberDetailsValidator<S>,
  getValidationContext: (controllerProps: ControllerProps) => S
) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      checkoutSettingsStore: {checkoutSettings},
      memberStore: {isAddressesAppInstalled, isMember},
    } = useControllerProps();
    const {wfConfig} = useControllerProps() as unknown as {wfConfig?: {formsById: any}};

    const {selectedAddressesServiceId, selectedAddressesService, setAddressServiceById, resetAddressService} =
      useSelectedServiceAddress(getAddressAndContactFromCheckout(checkout));

    const [editMode, setEditMode] = useState(false);
    const [isAddNewChecked, setIsAddNewChecked] = useState(false);
    const [areMemberDetailsValid, setAreMemberDetailsValid] = useState(true);

    const validationContext = getValidationContext(useControllerProps());

    useEffect(
      () => {
        if (!isMember || !isAddressesAppInstalled || !wfConfig?.formsById) {
          return;
        }
        const validateData = async (addressAndContact: AddressWithContactModel) => {
          const isValid = await validator({
            formsById: wfConfig?.formsById,
            addressAndContact,
            checkoutSettings,
            context: validationContext,
          });

          setAreMemberDetailsValid(isValid);
        };

        selectedAddressesService && void validateData(selectedAddressesService);
      },
      /* eslint-disable react-hooks/exhaustive-deps*/ [
        validationContext,
        checkoutSettings,
        wfConfig?.formsById,
        selectedAddressesServiceId,
      ]
    );

    const resetMemberDetailsState = () => {
      resetAddressService();
      setEditMode(false);
    };

    return (
      <MemberDetailsDataContext.Provider
        value={{
          selectedAddressesServiceId,
          selectedAddressesService,
          setAddressServiceById,
          editMode,
          setEditMode,
          resetMemberDetailsState,
          areMemberDetailsValid,
          isAddNewChecked,
          setIsAddNewChecked,
        }}>
        <Component {...props} />
      </MemberDetailsDataContext.Provider>
    );
  };
}

function useSelectedServiceAddress(addressAndContact?: AddressWithContactModel) {
  const {
    memberStore: {addressesInfo, isAddressesAppInstalled, isMember, defaultAddressId},
  } = useControllerProps();

  const getInitialSelectedAddressesServiceId = (): string | undefined => {
    if (!isMember || !isAddressesAppInstalled) {
      return undefined;
    }
    if (addressAndContact?.addressesServiceId) {
      return addressAndContact?.addressesServiceId;
    }
    if (!addressAndContact) {
      return addressesInfo.addresses?.length ? defaultAddressId : ADD_NEW_ADDRESS_ID;
    }

    return undefined;
  };

  const getMemberAddressBySelectedServiceId = (id?: string) =>
    addressesInfo.addresses?.find((address) => address.addressesServiceId === id);

  const [selectedAddressesServiceId, setSelectedAddressesServiceId] = useState(getInitialSelectedAddressesServiceId);
  const [selectedAddressesService, setSelectedAddressesService] = useState(
    getMemberAddressBySelectedServiceId(selectedAddressesServiceId)
  );

  const setAddressServiceById = (addressesServiceId?: string) => {
    setSelectedAddressesServiceId(addressesServiceId);
    setSelectedAddressesService(getMemberAddressBySelectedServiceId(addressesServiceId));
  };

  const resetAddressService = () => {
    setAddressServiceById(getInitialSelectedAddressesServiceId());
  };

  return {
    selectedAddressesService,
    selectedAddressesServiceId,
    setAddressServiceById,
    resetAddressService,
  };
}

export function useMemberDetailsData() {
  const {
    selectedAddressesServiceId,
    selectedAddressesService,
    setAddressServiceById,
    editMode,
    setEditMode,
    resetMemberDetailsState,
    areMemberDetailsValid,
    isAddNewChecked,
    setIsAddNewChecked,
  } = useContext(MemberDetailsDataContext);

  return {
    selectedAddressesServiceId,
    selectedAddressesService,
    setAddressServiceById,
    editMode,
    setEditMode,
    resetMemberDetailsState,
    areMemberDetailsValid,
    isAddNewChecked,
    setIsAddNewChecked,
  };
}
