import {ApiAddress} from '@wix/ambassador-ecom-v1-checkout/types';
import {StreetAddressModel} from './StreetAddress.model';
import {ApiAddressFragment} from '../../gql/graphql';

export class AddressModel {
  public streetAddress?: StreetAddressModel;
  public addressLine?: string;
  public addressLine2?: string;
  public city?: string;
  public postalCode?: string;
  public country?: string;
  public subdivision?: string;
  public countryFullname?: string;
  public subdivisionFullname?: string;
  constructor(address?: ApiAddress | ApiAddressFragment | null) {
    address = address ?? /* istanbul ignore next */ {};
    this.streetAddress = address.streetAddress ? new StreetAddressModel(address.streetAddress) : undefined;
    this.addressLine = address.addressLine ?? /* istanbul ignore next */ undefined;
    this.addressLine2 = address.addressLine2 ?? /* istanbul ignore next */ undefined;
    this.city = address.city ?? /* istanbul ignore next */ undefined;
    this.postalCode = address.postalCode ?? /* istanbul ignore next */ undefined;
    this.countryFullname = address.countryFullname ?? /* istanbul ignore next */ undefined;
    this.subdivisionFullname = address.subdivisionFullname ?? /* istanbul ignore next */ undefined;
    this.country = address.country ?? /* istanbul ignore next */ undefined;
    this.subdivision = address.subdivision ?? /* istanbul ignore next */ undefined;
  }
}
